import React, { useRef, useState } from "react"
import { isMobile } from "react-device-detect"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import { ThemeProvider, styled } from "@mui/material/styles"
import { Schedule } from "@mui/icons-material"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import moment from "moment"
import { Swiper, SwiperSlide } from "swiper/react"
import {
  Pagination,
  FreeMode,
  Autoplay,
  Navigation as SwiperNavigation,
} from "swiper"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/free-mode"
import "swiper/css/effect-cards"
import "react-h5-audio-player/lib/styles.css"

import Image from "../components/Image"
import SEO from "../components/seo"
import BreadCrumbs from "../components/bread-crumbs"
import "./article-details.scss"
import "../pages/popup-cta.scss"
import theme from "../theme"
import { Navigation } from "../components/contentful"
import { RichText } from "../components/contentful"
import CtaBlock from "../components/cta-blocks"
import Link from "../components/Link"
import Footer from "../components/foot"
import Card from "../components/card"
import ShareButtons from "../components/share-social-buttons"
import { RenderContentType } from "../components/contentful"

const SwiperButton = styled("button")(({ theme }) => ({
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  border: "none",
  outline: "none",
  position: "relative",
  padding: "0px",
  border: "2px solid #D8D8D8",
  backgroundColor: "#FFFFFF",
  cursor: "pointer",
  "&:after": {
    fontSize: "16px",
    color: theme.palette.gray.contrastText,
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    left: 0,
  },
}))

const PrevButton = styled(SwiperButton)(({ theme }) => ({
  marginRight: "25px",
  "&:after": {
    content: "'\\25C0'",
  },
}))

const NextButton = styled(SwiperButton)(({ theme }) => ({
  "&:after": {
    content: "'\\25B6'",
  },
  "MuiCircularProgress-svg": {
    color: theme.palette.success.main,
  },
}))

export default function ArticleDetails(props) {
  const prevEl = useRef(null)
  const nextEl = useRef(null)
  const [progress, setProgress] = useState(0)

  const {
    data: { contentfulEducationalContent: data },
    pageContext,
  } = props

  const breadCrumbsList = [
    {
      title: "Learning Library",
      link: "/learning-library",
    },
    {
      title: pageContext.topic?.name,
      link: `/${pageContext.category?.slug}/${pageContext.topic?.slug}`,
    },
  ]

  const metaDescription = ""
  const metaTitle = ""

  return (
    <ThemeProvider theme={theme}>
      <SEO title={metaTitle} description={metaDescription} />
      <Navigation data={data?.topNavigation} />
      <Box backgroundColor="#2c2c2c">
        <Box className="container section-padding" paddingTop="20px">
          <BreadCrumbs breadCrumbsList={breadCrumbsList} />
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            marginTop="30px"
          >
            <Typography
              variant="bigTitle"
              color="text.secondary"
              maxWidth="768px"
              textAlign="center"
            >
              {data?.title.title}
            </Typography>
            <Typography
              maxWidth="768px"
              color="text.secondary"
              textAlign="center"
              marginY="20px"
            >
              {data.description}
            </Typography>
            <Box display="flex" alignItems="center" marginBottom="20px">
              <Schedule fontSize="small" color="success" />
              <Typography variant="body3" paddingX="5px" color="text.secondary">
                {data?.contentLengthduration}
              </Typography>
              <Typography
                fontWeight="light"
                variant="body3"
                color="text.secondary"
              >{`| Published on ${moment(data.createdAt).format(
                "MMM DD, YYYY"
              )}`}</Typography>
            </Box>
            <Box maxWidth={960}>
              <Image image={data?.titleImage} src={data?.titleImage?.url} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box
          className="container contentful-data"
          display="flex"
          alignItems="flex-start"
        >
          <Box flex="1" marginRight="40px" color="#25345c">
            <RichText content={data.content} />
          </Box>
          {!isMobile && data?.upperCta && (
            <Box
              backgroundColor="tan.main"
              sx={{
                position: "sticky",
                marginTop: "20px",
                top: "20px",
                width: "25%",
                padding: "20px 10px 40px 20px",
                borderBottom: "3px solid #bdbdbd",
              }}
            >
              <Box width="42px" height="42px">
                <Image image={data?.upperCta?.image} />
              </Box>
              <Typography variant="h4" fontSize="16px">
                {data?.upperCta?.title}
              </Typography>
              <Typography fontSize="16px">
                {data?.upperCta?.ctaDescription}
              </Typography>
              <Link>Read more</Link>
            </Box>
          )}
        </Box>
        {data?.midContentCta && <CtaBlock data={data?.midContentCta} fluid />}
        <Box className="container" display="flex" alignItems="flex-start">
          <Box flex="1" marginRight="40px">
            <RichText content={data?.lowerContent} />
          </Box>
          {data?.lowerCta && (
            <Box
              backgroundColor="tan.main"
              sx={{
                position: "sticky",
                marginTop: "20px",
                top: "20px",
                width: "25%",
                padding: "20px 10px 40px 20px",
                borderBottom: "3px solid #bdbdbd",
              }}
            >
              <Box width="42px" height="42px">
                <Image image={data?.lowerCta?.image} />
              </Box>
              <Typography variant="h4" fontSize="16px">
                {data?.lowerCta.title?.title}
              </Typography>
              <Typography fontSize="16px">
                {data?.lowerCta?.ctaDescription}
              </Typography>
              <Link>Read more</Link>
            </Box>
          )}
        </Box>
        {data?.author?.authorName && (
          <Box
            className="container author-info"
            borderTop="1px solid #d9d9d9"
            borderBottom="1px solid #d9d9d9"
            padding={8}
            marginBottom="50px"
            display="flex"
            gap={2}
          >
            <Box
              sx={{
                width: isMobile ? "40px" : "50px",
                height: isMobile ? "28px" : "50px",
                borderRadius: "50%",
                border: "1px solid #d9d9d9",
              }}
            >
              {data?.authorImage && data?.authorImage?.gatsbyImageData ? (
                <GatsbyImage
                  image={getImage(data?.authorImage)}
                  alt="author author_image"
                />
              ) : (
                <img
                  src={data?.authorImage?.file?.url}
                  width="50px"
                  height="50px"
                  style={{ borderRadius: "50%" }}
                />
              )}
            </Box>
            <Box>
              {data?.author?.authorName && (
                <Typography variant="h4">{data?.author?.authorName}</Typography>
              )}
              {data?.author?.authorName && (
                <Typography variant="body3" margin={0}>
                  {data?.author?.description?.description || ""}
                </Typography>
              )}
            </Box>
          </Box>
        )}
        <Box
          className="container author-info"
          paddingX={8}
          paddingBottom="50px"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <Typography variant="h4" fontSize={20}>
            Share article:
          </Typography>
          <ShareButtons
            title="ConfidantHealth"
            url={(typeof window !== "undefined" && window.location.href) || ""}
          />
        </Box>
      </Box>
      <Box backgroundColor="#f9f5ea">
        <Box
          className="container"
          paddingY={{
            md: 4,
            xs: 3,
          }}
          paddingX={{ md: 0, xs: 4 }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography marginBottom={3} variant="h3">
              Related Articles
            </Typography>
            <Box>
              <PrevButton ref={prevEl} />
              <NextButton ref={nextEl}>
                <CircularProgress
                  sx={{
                    position: "absolute",
                    top: "-2px",
                    left: "-2px",
                    width: "100%",
                    height: "100%",
                  }}
                  variant="determinate"
                  value={Math.round(progress * 100)}
                  color="success"
                  thickness={2}
                />
              </NextButton>
            </Box>
          </Box>
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            modules={[FreeMode, Pagination, Autoplay, SwiperNavigation]}
            autoplay
            navigation={{ prevEl: prevEl?.current, nextEl: nextEl?.current }}
            breakpoints={{
              600: {
                slidesPerView: "4",
                spaceBetween: 30,
              },
            }}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = prevEl.current
              swiper.params.navigation.nextEl = nextEl.current
            }}
            onActiveIndexChange={(swiper) => setProgress(swiper.progress)}
          >
            {data.topics.map(({ educationOrder, slug }) =>
              educationOrder?.map((item, index) => (
                <SwiperSlide key={index}>
                  <Card
                    image={item?.titleImage?.file?.url}
                    content={item.description}
                    title={item.title.title}
                    linkLabel="Read More"
                    linkUrl={`/${slug ?? ""}/${item.slug}/`}
                    bodyVariant="body3"
                  />
                </SwiperSlide>
              ))
            )}
          </Swiper>
        </Box>
      </Box>
      {data?.pageSections?.map((section) => (
        <RenderContentType key={section.id} data={section} />
      ))}
      {Boolean(data?.footer) && <Footer data={data?.footer} />}
    </ThemeProvider>
  )
}

export const query = graphql`
  query EducationalBySlug($articleSlug: String) {
    contentfulEducationalContent(slug: { eq: $articleSlug }) {
      id
      author {
        authorName
        description {
          description
        }
      }
      authorImage {
        file {
          url
          fileName
        }
        filename
        url
        gatsbyImageData
      }
      titleImage {
        id
        url
        gatsbyImageData
      }
      title {
        title
      }
      description
      topNavigation {
        componentStyle
        mainNavigationLinks
        confidantLogo {
          componentStyle
          captionTitle
          captionSubtitle
          image {
            gatsbyImageData
            url
            file {
              url
            }
          }
          url
        }
        link5Text
        link4Text
        link3Text
        link2Text
        link1Text
        link1ExpandedNav {
          rightColumnTitle
          rightColumnLinkText
          rightColumnLinkUrl
          rightColumnLinks {
            description
            urlOverride
            title
            icon {
              filename
              file {
                url
              }
              gatsbyImageData
            }
          }
          category1Links {
            title
            urlOverride
            description
            icon {
              url
              filename
              gatsbyImageData
            }
            linkedPage {
              ... on ContentfulEducationalContent {
                id
                internal {
                  type
                }
                slug
              }
              ... on ContentfulLocationHub {
                id
                internal {
                  type
                }
              }
              ... on ContentfulLocationLandingPage {
                id
                internal {
                  type
                }
                serviceSlug
                stateSlug
                citySlug
              }
              ... on ContentfulMainPage {
                id
                internal {
                  type
                }
                slug
              }
              ... on ContentfulServiceHub {
                id
                internal {
                  type
                }
                serviceSlug
              }
              ... on ContentfulServiceLandingPage {
                id
                internal {
                  type
                }
                slug
              }
            }
          }
        }
        link2ExpandedNav {
          rightColumnTitle
          rightColumnLinkText
          rightColumnLinkUrl
          rightColumnLinks {
            description
            urlOverride
            title
            icon {
              filename
              file {
                url
              }
              gatsbyImageData
            }
          }
          category1Links {
            title
            urlOverride
            description
            icon {
              url
              filename
              gatsbyImageData
            }
            linkedPage {
              ... on ContentfulEducationalContent {
                id
                internal {
                  type
                }
                slug
              }
              ... on ContentfulLocationHub {
                id
                internal {
                  type
                }
              }
              ... on ContentfulLocationLandingPage {
                id
                internal {
                  type
                }
                serviceSlug
                stateSlug
                citySlug
              }
              ... on ContentfulMainPage {
                id
                internal {
                  type
                }
                slug
              }
              ... on ContentfulServiceHub {
                id
                internal {
                  type
                }
                serviceSlug
              }
              ... on ContentfulServiceLandingPage {
                id
                internal {
                  type
                }
                slug
              }
            }
          }
        }
        link3ExpandedNav {
          rightColumnTitle
          rightColumnLinkText
          rightColumnLinkUrl
          rightColumnLinks {
            description
            urlOverride
            title
            icon {
              filename
              file {
                url
              }
              gatsbyImageData
            }
          }
          category1Links {
            title
            urlOverride
            description
            icon {
              url
              filename
              gatsbyImageData
            }
            linkedPage {
              ... on ContentfulEducationalContent {
                id
                internal {
                  type
                }
                slug
              }
              ... on ContentfulLocationHub {
                id
                internal {
                  type
                }
              }
              ... on ContentfulLocationLandingPage {
                id
                internal {
                  type
                }
                serviceSlug
                stateSlug
                citySlug
              }
              ... on ContentfulMainPage {
                id
                internal {
                  type
                }
                slug
              }
              ... on ContentfulServiceHub {
                id
                internal {
                  type
                }
                serviceSlug
              }
              ... on ContentfulServiceLandingPage {
                id
                internal {
                  type
                }
                slug
              }
            }
          }
        }
        link4ExpandedNav {
          rightColumnTitle
          rightColumnLinkText
          rightColumnLinkUrl
          rightColumnLinks {
            description
            urlOverride
            title
            icon {
              filename
              file {
                url
              }
              gatsbyImageData
            }
          }
          category1Links {
            title
            urlOverride
            description
            icon {
              url
              filename
              gatsbyImageData
            }
            linkedPage {
              ... on ContentfulEducationalContent {
                id
                internal {
                  type
                }
                slug
              }
              ... on ContentfulLocationHub {
                id
                internal {
                  type
                }
              }
              ... on ContentfulLocationLandingPage {
                id
                internal {
                  type
                }
                serviceSlug
                stateSlug
                citySlug
              }
              ... on ContentfulMainPage {
                id
                internal {
                  type
                }
                slug
              }
              ... on ContentfulServiceHub {
                id
                internal {
                  type
                }
                serviceSlug
              }
              ... on ContentfulServiceLandingPage {
                id
                internal {
                  type
                }
                slug
              }
            }
          }
        }
        link5ExpandedNav {
          rightColumnTitle
          rightColumnLinkText
          rightColumnLinkUrl
          rightColumnLinks {
            description
            urlOverride
            title
            icon {
              filename
              file {
                url
              }
              gatsbyImageData
            }
          }
          category1Links {
            title
            urlOverride
            description
            icon {
              url
              filename
              gatsbyImageData
            }
            linkedPage {
              ... on ContentfulEducationalContent {
                id
                internal {
                  type
                }
                slug
              }
              ... on ContentfulLocationHub {
                id
                internal {
                  type
                }
              }
              ... on ContentfulLocationLandingPage {
                id
                internal {
                  type
                }
                serviceSlug
                stateSlug
                citySlug
              }
              ... on ContentfulMainPage {
                id
                internal {
                  type
                }
                slug
              }
              ... on ContentfulServiceHub {
                id
                internal {
                  type
                }
                serviceSlug
              }
              ... on ContentfulServiceLandingPage {
                id
                internal {
                  type
                }
                slug
              }
            }
          }
        }
        navButton {
          buttonText
          buttonUrl
        }
        phoneNumber
      }
      topics {
        id
      }
      slug
      contentLengthduration
      contentAudio {
        url
      }
      content {
        raw
      }
      lowerContent {
        raw
      }
      upperCta {
        componentStyle
        contentAlignment
        backgroundColor
        ctaDescription: description
        title
        subtitle
        image {
          file {
            url
          }
          gatsbyImageData
        }
        placeholderText
        textBelowEmailInput
        iconsAndButtons {
          ... on ContentfulImagesWithLinks {
            id
            internal {
              type
            }
            componentStyle
            image {
              file {
                url
              }
              gatsbyImageData
              url
            }
            url
          }
          ... on ContentfulButton {
            id
            internal {
              type
            }
            buttonText
            buttonUrl
            openMethod
          }
        }
      }
      lowerCta {
        componentStyle
        contentAlignment
        backgroundColor
        ctaDescription: description
        title
        subtitle
        image {
          file {
            url
          }
          gatsbyImageData
        }
        placeholderText
        textBelowEmailInput
        iconsAndButtons {
          ... on ContentfulImagesWithLinks {
            id
            internal {
              type
            }
            componentStyle
            image {
              file {
                url
              }
              gatsbyImageData
              url
            }
            url
          }
          ... on ContentfulButton {
            id
            internal {
              type
            }
            buttonText
            buttonUrl
            openMethod
          }
        }
      }
      midContentCta {
        componentStyle
        contentAlignment
        backgroundColor
        ctaDescription: description
        title
        subtitle
        image {
          file {
            url
          }
          gatsbyImageData
        }
        placeholderText
        textBelowEmailInput
        iconsAndButtons {
          ... on ContentfulImagesWithLinks {
            id
            internal {
              type
            }
            componentStyle
            image {
              file {
                url
              }
              gatsbyImageData
              url
            }
            url
          }
          ... on ContentfulButton {
            id
            internal {
              type
            }
            buttonText
            buttonUrl
            openMethod
          }
        }
      }
      isPopular
      keywords
      createdAt
      topics {
        educationOrder {
          id
          slug
          title {
            title
          }
          description
          titleImage {
            file {
              url
              fileName
            }
            filename
            url
            gatsbyImageData
          }
        }
        slug
        name
      }
      pageSections {
        ... on ContentfulHero {
          id
          internal {
            type
          }
          logos {
            captionTitle
            captionSubtitle
            image {
              file {
                url
              }
              filename
              gatsbyImageData
            }
            url
          }
          bulletPoints {
            description {
              raw
            }
            title {
              title
            }
          }
          componentStyle
          backgroundColor
          placeholder
          textBelowInput
          title
          subtitle
          description {
            description
          }
          primaryButton {
            buttonText
            buttonUrl
            openMethod
          }
          secondaryButton {
            buttonText
            buttonUrl
            openMethod
          }
          supportingImages {
            id
            file {
              url
            }
            gatsbyImageData
          }
        }
        ... on ContentfulCtaBlocks {
          id
          internal {
            type
          }
          componentStyle
          contentAlignment
          backgroundColor
          ctaDescription: description
          title
          subtitle
          image {
            file {
              url
            }
            gatsbyImageData
          }
          placeholderText
          textBelowEmailInput
          iconsAndButtons {
            ... on ContentfulImagesWithLinks {
              id
              internal {
                type
              }
              componentStyle
              image {
                file {
                  url
                }
                gatsbyImageData
                url
              }
              url
            }
            ... on ContentfulButton {
              id
              internal {
                type
              }
              buttonText
              buttonUrl
              openMethod
            }
          }
        }
        ... on ContentfulDynamicContentSection {
          id
          internal {
            type
          }
          componentStyle
          backgroundColor
          tiitle
          subtitle
          description {
            description
          }
          contentSections {
            ... on ContentfulGridItem {
              id
              internal {
                type
              }
              title
              description {
                description
              }
              icon {
                file {
                  url
                }
                gatsbyImageData
                filename
              }
              image {
                file {
                  url
                }
                gatsbyImageData
                filename
              }
              linkUrl
              linkText
            }
            ... on ContentfulOldContentSection {
              id
              internal {
                type
              }
              alignment
              backgroundColor
              componentStyle
              contentAlignment
              description {
                description
              }
              linkText
              link
              richText {
                raw
              }
              links {
                title
                urlOverride
              }
              primaryButton {
                buttonText
                buttonUrl
                openMethod
              }
              subtitle
              heading
              image {
                file {
                  url
                }
                filename
                gatsbyImageData
              }
              supportingImages {
                file {
                  url
                }
                filename
                url
                gatsbyImageData
              }
              photostack {
                filename
                url
                file {
                  url
                  fileName
                }
                gatsbyImageData
              }
              image {
                file {
                  url
                  fileName
                }
                gatsbyImageData
              }
            }
          }
        }
        ... on ContentfulGrid {
          id
          internal {
            type
          }
          backgroundColor
          componentStyle
          gridItems {
            ... on ContentfulEducationalContent {
              id
              internal {
                type
              }
              authorName
              authorOrganization
              edDescription: description
              content {
                raw
              }
              authorImage {
                file {
                  url
                }
                gatsbyImageData
              }
              titleImage {
                file {
                  url
                }
                gatsbyImageData
              }
              title {
                title
              }
            }
            ... on ContentfulImagesWithLinks {
              id
              internal {
                type
              }
              componentStyle
              image {
                file {
                  url
                }
                gatsbyImageData
              }
              url
              captionTitle
              captionSubtitle
            }
            ... on ContentfulGridItem {
              id
              internal {
                type
              }
              linkUrl
              linkText
              description {
                description
              }
              gridTitle: title
              icon {
                file {
                  url
                }
                filename
                gatsbyImageData
              }
              image {
                file {
                  url
                }
                filename
                gatsbyImageData
              }
            }
          }
        }
        ... on ContentfulInterstitials {
          id
          internal {
            type
          }
          backgroundColor
          componentStyle
          linkUrl
          linkText
          primaryButton {
            buttonText
            buttonUrl
            openMethod
          }
          secondaryButton {
            buttonText
            buttonUrl
            openMethod
          }
          text
        }
        ... on ContentfulOldContentSection {
          id
          internal {
            type
          }
          alignment
          contentAlignment
          backgroundColor
          componentStyle
          description {
            description
          }
          heading
          link
          linkText
          richText {
            raw
          }
          links {
            title
            urlOverride
          }
          primaryButton {
            buttonText
            buttonUrl
            openMethod
          }
          subtitle
          videoUrl
          supportingImages {
            file {
              url
            }
            filename
            gatsbyImageData
          }
          image {
            file {
              url
              fileName
            }
            gatsbyImageData
          }
          supportingItems {
            ... on ContentfulImagesWithLinks {
              id
              internal {
                type
              }
              captionTitle
              captionSubtitle
              componentStyle
              url
              image {
                file {
                  url
                  fileName
                }
                gatsbyImageData
              }
            }
            ... on ContentfulGridItem {
              id
              internal {
                type
              }
              linkUrl
              linkText
              description {
                description
              }
              image {
                file {
                  url
                }
                filename
                gatsbyImageData
                description
              }
              icon {
                file {
                  url
                }
                gatsbyImageData
                filename
              }
              heading: title
            }
            ... on ContentfulFaqStatsAccordionItems {
              id
              internal {
                type
              }
              description {
                raw
              }
              title {
                title
              }
            }
          }
        }
        ... on ContentfulOldHeaders {
          id
          internal {
            type
          }
          componentStyle
          subtitle
          title
          imageOrVideo {
            file {
              url
            }
            gatsbyImageData
          }
          description {
            description
          }
          featuredIcons {
            file {
              url
            }
            gatsbyImageData
          }
        }
        ... on ContentfulOldSectionHeaders {
          id
          internal {
            type
          }
          name
          backgroundColor
          componentStyle
        }
        ... on ContentfulQuotesAndTestimonials {
          id
          internal {
            type
          }
          backgroundColor
          componentStyle
          testimonials {
            backgroundColorCode
            description
            name
          }
        }
        ... on ContentfulForm {
          id
          internal {
            type
          }
          componentStyle
          formDescription: description
          subtitle
          title
          embeddedFormUrl
          supportingContent {
            raw
          }
        }
        ... on ContentfulFeaturedIn {
          id
          internal {
            type
          }
          componentStyle
          backgroundColor
          title
          imagesWithLinks {
            url
            image {
              filename
              file {
                url
              }
              gatsbyImageData
            }
            captionTitle
            captionSubtitle
          }
        }
        ... on ContentfulTabbedContentSection {
          id
          internal {
            type
          }
          componentStyle
          column4Title
          column3Title
          column2Title
          column1Title
          backgroundColor
          title
          description {
            description
          }
          column1Content {
            alignment
            description {
              description
            }
            heading
            subtitle
            image {
              url
              filename
              gatsbyImageData
            }
          }
          column2Content {
            alignment
            description {
              description
            }
            heading
            subtitle
            image {
              url
              filename
              gatsbyImageData
            }
          }
          column3Content {
            alignment
            description {
              description
            }
            heading
            subtitle
            image {
              url
              filename
              gatsbyImageData
            }
          }
          column4Content {
            alignment
            description {
              description
            }
            heading
            subtitle
            image {
              url
              filename
              gatsbyImageData
            }
          }
        }
      }
      footer {
        column1Links {
          title
          urlOverride
        }
        column1Title
        column2Links {
          urlOverride
          title
        }
        column2Title
        column3Links {
          title
          urlOverride
        }
        column3Title
        column4Links {
          urlOverride
          title
        }
        column4Title
        backgroundColor
        title
        description {
          description
        }
        copyrightText
        linksTitle
      }
    }
  }
`
