import React from "react"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import MuiSnackbar from "@mui/material/Snackbar"
import Alert from "@mui/material/Alert"
import CopyAllIcon from "@mui/icons-material/CopyAll"
import { Typography } from "@mui/material"

export default function CopyLink() {
  const [alertOpen, setAlertOpen] = React.useState(false)

  const CopyToClipboard = (toCopy) => {
    const el = document.createElement(`textarea`)
    el.value = toCopy
    el.setAttribute(`readonly`, ``)
    el.style.position = `absolute`
    el.style.left = `-9999px`
    document.body.appendChild(el)
    el.select()
    document.execCommand(`copy`)
    document.body.removeChild(el)
  }

  return (
    <>
      <IconButton
        sx={{
          backgroundColor: "#FFF",
          border: "1px solid #d9d9d9",
          borderRadius: 0,
          padding: 1,
          height: 38,
        }}
        size="small"
        color="info"
        variant="contained"
        onClick={() => {
          CopyToClipboard(
            (typeof window !== "undefined" && window.location.href) || ""
          )
          setAlertOpen(true)
        }}
      >
        <CopyAllIcon />
        <Typography variant="body3" color="#646c73" marginLeft={1}>
          Copy link
        </Typography>
      </IconButton>

      <MuiSnackbar
        open={alertOpen}
        autoHideDuration={1000}
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={() => setAlertOpen(false)}
          severity="success"
          variant="filled"
        >
          Link copied
        </Alert>
      </MuiSnackbar>
    </>
  )
}
