import React from "react"
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share"
import Box from "@mui/material/Box"
import "./share-social-buttons.scss"
import CopyLinkButton from "./copy-link-button"

const ShareButtons = ({ title, url, twitterHandle, tags }) => {
  return (
    <Box display="flex" alignItems="center" gap={3}>
      <Box display="flex" alignItems="center" gap={3}>
        <FacebookShareButton
          url={url}
          style={{
            border: "1px solid #d9d9d9",
            padding: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FacebookIcon size={30} round={true} iconFillColor="white" />
        </FacebookShareButton>
        <LinkedinShareButton
          url={url}
          style={{
            border: "1px solid #d9d9d9",
            padding: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LinkedinIcon size={30} round={true} />
        </LinkedinShareButton>
        <TwitterShareButton
          url={url}
          title={title}
          via={twitterHandle}
          hashtags={tags}
          style={{
            border: "1px solid #d9d9d9",
            padding: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TwitterIcon size={30} round={true} />
        </TwitterShareButton>
      </Box>
      <Box>
        <CopyLinkButton />
      </Box>
    </Box>
  )
}
export default ShareButtons
